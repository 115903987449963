<template>
    <div>
        支付成功
    </div>
</template>
<style>

</style>
<script>
export default {
    
}
</script>